import React from "react"
import Layout from "./src/components/layout"

export const onInitialClientRender = () => {
  require("typeface-raleway");
  require("typeface-open-sans");
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}